.grecaptcha-badge {
  display: none;
}

#webWidget {
  padding-top: env(safe-area-inset-top);
  padding-bottom: env(safe-area-inset-bottom);
}

.box {
  display: flex;
  position: relative;
  flex-direction: column;
}
